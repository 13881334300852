
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../Type/Type.module.scss';

.Expander {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  &.isSpacious {
    border-top: 1px solid $color-primary-grey-light;
    border-bottom: 1px solid $color-primary-grey-light;
    display: block;
    margin-right: spacing(3);
    width: 100%;
    padding: spacing(2.5) 0 spacing(0.5);

    &:focus {
      outline: 0;
    }

    & + & {
      border-top: 0;
    }

    @include mq($breakpoint-desktop) {
      padding: spacing(3.5) 0 spacing(1.5);
    }

    @include mq($breakpoint-desktopMedium) {
      padding: spacing(4.5) 0 spacing(2.5);
    }
  }

  &.removeBottomBorder {
    border-bottom: 0;
  }
}

.Content {
  overflow: hidden;
}

.Toggle {
  display: flex;
  align-items: center;

  &:hover {
    color: $color-primary-grey;
    transition: $duration-short $easing-standard;
  }

  .isSpacious {
    width: 100%;
    justify-content: space-between;
    font-weight: normal;
    margin-bottom: spacing(2);
  }

  .isSpacious > & {
    width: 100%;
    justify-content: space-between;
    font-weight: normal;
    margin-bottom: spacing(2);
  }

  .isSpacious .ToggleInner {
    width: 100%;
  }
}

.ToggleInner {
  font-weight: bold;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.isClassificationFirst {
    justify-content: flex-start;
    .TitleWrapper {
      order: 1;
    }
    .RatingWrapper {
      order: 0;
      align-items: flex-start;

      .Rating {
        margin: 0 spacing(1) 0 0;
      }
    }
  }
}

.TitleWrapper {
  order: 0;

  &.isTitlesStacked {
    display: flex;
    flex-direction: column;
  }
}

.Title {
  color: $color-primary-teal;
  @extend .base;

  .isSpacious & {
    @extend .display1;

    margin-right: spacing(2);
  }
}

.SubTitle {
  .isSpacious & {
    @extend .base-large;
  }
  &_version {
    font-weight: 600;
    color: $color-primary-teal;
    margin-right: 0.3em;
  }
  &_cuts {
    margin-left: 0.5em;
  }
}

.Icon {
  line-height: 7px;
  margin-left: spacing(1);

  path {
    fill: $color-primary-black;

    .isDark & {
      fill: $color-primary-white;
    }
  }

  &.rotated {
    transform: scaleY(-1);
    transform-origin: 50% 50%;
  }
}

.RatingWrapper {
  order: 1;
}

.Rating {
  margin: 0 spacing(1);
  vertical-align: middle;
}
