
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContentSummaryGrid {
  display: block;
  color: $color-primary-grey;
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}

.Title {
  padding-bottom: spacing(1);
}

.Subtitle {
  padding-bottom: spacing(4);
  color: $color-primary-grey;

  @include mq($breakpoint-desktopMedium) {
    padding-bottom: spacing(8);
  }
}

.isDark {
  .Subtitle {
    color: $color-primary-white;
  }
}
