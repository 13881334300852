
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-common-white, 0.8);
}

.LoadingDots {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $color-primary-black;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: animation1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 8px;
      animation: animation2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 32px;
      animation: animation2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 56px;
      animation: animation1 0.6s infinite;
    }
  }
}

@keyframes animation1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animation2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes animation3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
