
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ButtonStandard {
  padding: spacing(1.5) spacing(2);
  line-height: spacing(2.5);
  border-radius: $radius-small;
  background-color: $color-primary-teal;
  color: $color-primary-white;
  font-weight: bold;
  font-size: spacing(2.25); //18px;
  transition: $duration-short $easing-standard;

  &:hover,
  &:focus,
  &:active {
    transition: $duration-short $easing-standard;
    background-color: $color-primary-black;
    color: $color-primary-white;
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(2.75); //22px;
    padding: spacing(2);
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(2.75); //22px;
    padding: spacing(2.5) spacing(2);
  }
}

.disabled {
  background-color: $color-common-grey50;
}
