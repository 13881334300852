
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ContentSummaryGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: spacing(220);
  padding-top: 20vw;

  @include mq($breakpoint-tablet) {
    padding-top: 14vw;
  }

  @include mq($breakpoint-mobileNav) {
    padding-top: 11vw;
  }

  @include mq($breakpoint-desktop) {
    padding-top: 50px;
    flex-wrap: nowrap;
  }

  @include mq($breakpoint-desktopMedium) {
    padding-top: 70px;
  }
}

.centered {
  justify-content: center;
}

.staggered {
  align-items: flex-start;
}
