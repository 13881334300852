
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.FieldFeedback {
  margin-top: spacing(1);
}

@each $key, $value in $color-status-list {
  .#{$key} {
    color: $value !important;
  }
}
