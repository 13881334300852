
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import 'common';

.ContentSummaryWrapper {
  position: relative;

  @include mq($breakpoint-desktop) {
    margin: 0 spacing(1.5);
  }

  @include mq($breakpoint-desktopMedium) {
    margin: 0 spacing(2.5);
  }

  @include mq($breakpoint-desktopLarge) {
    margin: 0 spacing(3);
  }

  @include mq(0, $breakpoint-tablet - 1) {
    width: 100% !important;
    margin: 0 auto spacing(5);

    &:nth-child(2) {
      width: 80% !important;
    }
  }

  @include mq($breakpoint-tablet, $breakpoint-desktop - 1) {
    width: 50% !important;
    margin-bottom: spacing(7);

    &:nth-child(1) {
      width: 45% !important;
    }

    &:nth-child(2) {
      width: 40% !important;
      order: 2;
      margin-left: 5%;
    }

    &:nth-child(3) {
      position: relative;
      width: 50% !important;
      top: 96px;
    }
  }

  @include mq($breakpoint-desktop) {
    &:nth-child(1) {
      margin-top: spacing(2);
    }

    &:nth-child(2) {
      margin-top: spacing(10);
    }
  }
}

.ContentSummary {
  color: $color-primary-white;
  position: relative;
}

.ContentSummaryInner {
  padding: spacing(2);

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(3);
  }
}

.Pointer {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, 0);
}

.Squiggle {
  position: absolute;
  top: -210px;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, 0);

  @include mq($breakpoint-tablet) {
    top: -290px;
  }

  @include mq($breakpoint-desktop, $breakpoint-desktopMedium - 1) {
    top: -210px;
  }

  @include mq($breakpoint-desktopLarge) {
    top: -380px;
  }

  .ContentSummaryWrapper:nth-child(3) & {
    transform: scaleX(-1) translate(-50%, 0);
    left: auto;
    right: 50%;
  }
}

.SquiggleIcon {
  padding-left: 35px;

  @include mq($breakpoint-tablet) {
    width: 100px !important;
    padding-left: 45px;
  }

  @include mq($breakpoint-desktop, $breakpoint-desktopMedium - 1) {
    width: 70px !important;
    padding-left: 33px;
  }

  @include mq($breakpoint-desktopLarge) {
    width: 135px !important;
    padding-left: 63px;
  }
}

.Triangles {
  position: absolute;
  top: -70px;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, 0);
}

.Heading,
.Body {
  color: $color-primary-white;
}

.Heading {
  margin-top: spacing(1);

  @include mq($breakpoint-desktopMedium) {
    margin-top: spacing(1.5);
  }
}

.Body {
  margin-top: spacing(0.5);

  @include mq($breakpoint-desktopMedium) {
    margin-top: spacing(1);
  }
}
