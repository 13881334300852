.ContentSummary {
  margin-bottom: spacing(4);
}

a.ContentSummary {
  display: block;

  &:focus,
  &:hover {
    .Heading {
      text-decoration: underline;
    }
  }
}

.Image {
  border-radius: $radius-standard;
  overflow: hidden;
}
